import React from "react";

const Background = ({ children }) => {
  return (
    // Remove transition-all to disable the background color transition.
    <body className="transition-all bg-white dark:bg-black">{children}</body>
  );
};

export default Background;
