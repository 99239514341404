import React from "react";
import Helmet from "react-helmet";
import config from "../utils/siteconfig";
import ThemeToggle from "./shared/themeToggle";
import Footer from "./shared/footer";

// import Footer from "./footer";

const Template = ({ header, footer, children }) => {
  return (
    <div className="flex flex-col min-h-screen antialiased text-black bg-gray-100 siteRoot font-body dark:bg-dmblack-500 dark:text-white font-gotham">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="en" />
        <body />
        {/* <link rel="icon" href={favicon} /> */}
      </Helmet>

      <main className="text-base font-body">{children}</main>
      <Footer />
      {/* {footer == "No" ? "" : <Footer />} */}
    </div>
  );
};

export default Template;
