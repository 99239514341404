module.exports = {
  siteTitle: "Blake Powers Portfolio",
  siteTitleAlt: "Blake's Portfolio", // This allows an alternative site title for SEO schema.
  publisher: "Blake Powers", // Organization name used for SEO schema
  siteDescription: "",
  siteUrl: "https://marketingsuperpowers.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerListingPage: 10, // Number of posts shown on the 1st page of of the Blog Listing Page
  postsPerPage: 9, // Number of posts shown on paginated pages
  author: "Blake Powers", // Author for RSS author segment and SEO schema
  authorUrl: "https://marketingsuperpowers.com", // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: "", // Change for Twitter Cards
  shortTitle: "Blake", // Used for App manifest e.g. Mobile Home Screen
  shareImage: "/logos/share.jpg", // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: "/logos/logo-512.png", // Logo used for SEO, RSS, and App manifest
  backgroundColor: "#e9e9e9", // Used for Offline Manifest
  themeColor: "#121212", // Used for Offline Manifest
  copyright: "© 2021", // Copyright string for the RSS feed
};
