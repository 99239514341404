import React from "react";

const Container = (props) => {
  return (
    <div className="px-8 md:px-10 lg:px-16 lg:container lg:mx-auto">
      {props.children}
    </div>
  );
};

export default Container;
