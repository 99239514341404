import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label>
          Light/Dark Theme{" "}
          <input
            type="checkbox"
            id="toggle"
            className="theme-toggler__checkbox"
            onChange={(e) => toggleTheme(e.target.checked ? "dark" : "light")}
            checked={theme === "dark"}
          />
          <span aria-hidden="true" className="theme-toggler__switch"></span>
        </label>
      )}
    </ThemeToggler>
  );
}
