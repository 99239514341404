import React from "react";

const H2Title = ({ id, ...props }) => {
  return (
    <div className="py-4">
      {props.center == null ? (
        <h2
          className="pb-3 text-2xl font-black font-gotham text-gnNavy-400 dark:text-white"
          id={props.uniqid}
        >
          {props.children}
        </h2>
      ) : (
        <h2
          className="pb-3 text-2xl font-black text-center font-gotham text-gnNavy-400 dark:text-white"
          id={props.uniqid}
        >
          {props.children}
        </h2>
      )}
    </div>
  );
};

export default H2Title;
