import React from "react";
import Background from "./background";
import ThemeToggle from "./themeToggle";
import H2Title from "./h2-title";
import Container from "../container";

const Footer = ({ children }) => {
  return (
    <Container>
      <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
        <div className="lg:col-span-12 mt-28">
          <div className="grid gap-4 lg:gap-6 md:grid-cols-12">
            <div className="lg:col-span-8 md:col-span-12">
              <H2Title>About Me</H2Title>
            </div>
            <div className="lg:col-span-4 md:col-span-12">
              <H2Title>Contact</H2Title>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <ThemeToggle />
        </div>
      </div>
    </Container>
  );
};

export default Footer;
